import { TranscarentSsoControllerService } from "@9amhealth/openapi";
import React, { FC, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import envVariables from "src/lib/envVariables";
import parseJwt from "src/lib/parseJwt";
import { authenticationState } from "src/state/state";
import Loader from "../Loader/Loader";
import Translate from "../Translate/Translate";
import { NiceError } from "src/ui/styled/NiceError";

const HandleOneTimeToken: FC = () => {
  const [searchParams] = useSearchParams();
  const [error, setError] = React.useState<string | null>(null);
  const [response, setResponse] = React.useState<unknown | null>(null);
  const token = searchParams.get("token");
  const currentAuthToken = authenticationState.accessToken;
  const isProd = envVariables.APP_ENV === "production";

  const currentTokenDetails = useMemo(() => {
    if (currentAuthToken) {
      try {
        const decoded = parseJwt(currentAuthToken);
        return {
          token,
          userId: decoded.sub,
          expired: decoded.expired
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        setError("Invalid Auth Token");
        return null;
      }
    }
    return null;
  }, [currentAuthToken]);

  const exchangeTokenDetails = useMemo(() => {
    if (token) {
      try {
        const decoded = parseJwt(token);
        return {
          token,
          userId: decoded.sub
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        setError("Invalid Exchange Token");
        return null;
      }
    }
    setError("Missing Exchange Token");
    return null;
  }, [token]);

  const handleAuthSuccess = () => {
    window.location.replace("/app/home?from=tc-ott");
  };

  useEffect(() => {
    if (exchangeTokenDetails) {
      if (
        currentTokenDetails &&
        !currentTokenDetails.expired &&
        currentTokenDetails.userId === exchangeTokenDetails.userId
      ) {
        handleAuthSuccess();
        return;
      }

      void TranscarentSsoControllerService.exchangeToken({
        token: exchangeTokenDetails.token
      })
        .then((res) => {
          void authenticationState
            .setCredentials({
              accessToken: res.data.accessToken,
              refreshToken: res.data.refreshToken
            })
            .then(() => {
              handleAuthSuccess();
            })
            .catch(() => {
              setError("Could not set credentials");
            });
        })
        .catch((err: unknown) => {
          setResponse(err);
          if (err instanceof Error) {
            setError(err.message);
          }
        });
    }
  }, [exchangeTokenDetails, currentTokenDetails]);

  if (error) {
    return (
      <NiceError>
        <h1>
          <Translate msg="auth.ott.error" />
        </h1>
        {!isProd && (
          <>
            <em>error:</em>
            <pre>{error}</pre>
            <em>response:</em>
            <pre>{JSON.stringify(response, null, 2)}</pre>
          </>
        )}
      </NiceError>
    );
  }

  return <Loader active fixed absolute fullPage bg="partner" />;
};

export default HandleOneTimeToken;
